import styled, { css } from 'styled-components'
import { ErrorBanner as ErrorBannerBase } from '../Banner/Banner'
import { Box } from '../Box'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { getTypographyIntent } from '../Typography'
import { borderRadius } from '../_styles/borderRadius'
import { colors } from '../_styles/colors'
import { spacing } from '../_styles/spacing'

export const StyledErrorBanner = styled(ErrorBannerBase)`
  margin-top: ${spacing.lg}px;
  flex-shrink: 0;
`
export const StyledErrorBannerDetails = styled.ul`
  margin: ${spacing.xs}px;
  padding: 0;
  list-style-position: inside;
  list-style-type: disc;
`
export const StyledDropzoneIcon = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  color: ${colors.gray45};
  margin-bottom: ${spacing.lg}px;
  transition: all 250ms ease-out;

  ${({ disabled }) =>
    disabled &&
    css`
      height: 72px;
      margin-bottom: ${spacing.sm}px;
    `}

  svg {
    width: auto;
    height: 100%;
  }
`

const activeDropzone = css`
  outline: none;
  border: 2px dashed ${colors.blue50};
  background-color: ${colors.blue96};

  & ${StyledDropzoneIcon} {
    color: ${colors.blue50};
    transform: scale(0.95);
  }
`

const disabledDropzone = css`
  color: ${colors.gray70};
  border: 2px dashed ${colors.gray85};
  background-color: ${colors.gray98};
  cursor: not-allowed;

  & ${StyledDropzoneIcon} {
    color: ${colors.gray85};
    transform: none;
  }
`

export const StyledDropzoneContainer = styled.div<{
  active: boolean
  disabled: boolean
}>`
  ${getTypographyIntent('body')}

  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 92px;
  padding: ${spacing.lg}px;
  color: ${colors.gray40};
  border: 2px dashed ${colors.gray70};
  background-color: ${colors.gray96};
  border-radius: ${borderRadius.md}px;
  text-align: center;
  letter-spacing: 0.25px;
  transition: all 250ms ease-out;
  cursor: grab;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        ${activeDropzone}
      }
    `}

  ${({ disabled }) => disabled && disabledDropzone}

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover,
      &:focus {
        ${disabledDropzone}
      }
    `}

  ${({ active, disabled }) => active && !disabled && activeDropzone}
`

export const StyledDropzoneMessage = styled.div`
  user-select: none;
`

export const StyledUploadButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.sm}px;
`

export const StyledDropzoneCaption = styled.span<{ error: boolean }>`
  color: ${({ error }) => error && colors.gray15};
`

export const StyledDropzoneWrapper = styled(Flex)`
  height: 100%;
`

export const StyledDropzoneFocusDetector = styled(Box)`
  height: 100%;
`
