import { Ban, Image } from '@procore/core-icons/dist'
import { equals, omit } from 'ramda'
import React from 'react'
import styled from 'styled-components'
import { Banner } from '../Banner'
import { Box } from '../Box'
import { Button } from '../Button'
import { Token } from '../Token'
import { Tooltip } from '../Tooltip'
import { Typography } from '../Typography'
import { useI18nContext } from '../_hooks/I18n'
import { useResizeObserver } from '../_hooks/ResizeObserver'
import { useFocusWithin } from '../_hooks/useFocusWithin'
import type { DivAttributes, Props } from '../_utils/types'
import {
  StyledDropzoneCaption,
  StyledDropzoneContainer,
  StyledDropzoneFocusDetector,
  StyledDropzoneIcon,
  StyledDropzoneMessage,
  StyledDropzoneWrapper,
  StyledErrorBanner,
  StyledErrorBannerDetails,
  StyledUploadButton,
} from './Dropzone.styles'
import type {
  ContainerProps,
  ContentProps,
  DropErrorType,
  DropzoneContentProps,
  DropzoneErrorBannerProps,
  DropzoneInputProps,
  DropzoneProps,
  DropzoneRef,
  DropzoneRootProps,
  FileRejectionReason,
  MultipleErrorsProps,
} from './Dropzone.types'
import { dropErrors, fileRejectionReason } from './Dropzone.types'
import { getFileDetail, isZeroSize } from './Dropzone.utils'

const extendedViewHeightBreakpoint = 172

const DropzoneErrorBannerBase = React.forwardRef<
  HTMLDivElement,
  DropzoneErrorBannerProps & DivAttributes
>(({ error, onDismiss, qa, fileRejections, ...props }, ref) => {
  const I18n = useI18nContext()
  const [isExpanded, setExpanded] = React.useState(false)

  React.useEffect(() => {
    setExpanded(false)
  }, [error.title, error.body, error.message])

  if (error.type === dropErrors.reset) {
    return null
  }

  return (
    <StyledErrorBanner {...props} ref={ref}>
      <Banner.Content>
        <Banner.Title>{error.title}</Banner.Title>
        <Banner.Body>{error.message}</Banner.Body>
      </Banner.Content>
      {error.body ? (
        <Banner.Action>
          <Button
            data-qa={qa?.showErrorDetails}
            onClick={() => setExpanded((prev) => !prev)}
          >
            {isExpanded
              ? I18n.t('core.dropzone.hideDetails')
              : I18n.t('core.dropzone.showDetails')}
          </Button>
        </Banner.Action>
      ) : null}
      <Banner.Dismiss onClick={onDismiss} data-qa={qa?.hideError} />
      {error.body ? (
        <Banner.ExpandableContent expanded={isExpanded}>
          {error.body}
        </Banner.ExpandableContent>
      ) : null}
    </StyledErrorBanner>
  )
})

export function MultipleErrors(props: MultipleErrorsProps) {
  const I18n = useI18nContext()

  const filesByErrorType = props.fileRejections.reduce(
    (acc, rejection) => {
      rejection.errors.forEach((error) => {
        const dropErrorType = dropErrorsByReason[error.code]
        acc[dropErrorType].push(rejection.file)
      })

      return acc
    },
    {
      [dropErrors.fileAmount]: [],
      [dropErrors.minFileSize]: [],
      [dropErrors.maxFileSize]: [],
      [dropErrors.fileType]: [],
      [dropErrors.oneFileAtATime]: [],
      [dropErrors.reset]: [],
      [dropErrors.multipleErrors]: [],
    } as Record<DropErrorType, File[]>
  )

  const messages: Record<DropErrorType, string> = {
    [dropErrors.fileAmount]: I18n.t('core.dropzone.maxFileNumberErrorGroup', {
      count: props.maxFiles,
    }),
    [dropErrors.minFileSize]: isZeroSize(props.minSize)
      ? I18n.t('core.dropzone.zeroFileSizeErrorGroup')
      : I18n.t('core.dropzone.minFileSizeErrorGroup', {
          sizeInMegabytes: getSizeInMB(props.minSize as number),
        }),
    [dropErrors.maxFileSize]: I18n.t('core.dropzone.maxFileSizeErrorGroup', {
      sizeInMegabytes: getSizeInMB(props.maxSize as number),
    }),
    [dropErrors.fileType]: I18n.t('core.dropzone.wrongFileTypeErrorGroup'),
    [dropErrors.reset]: '',
    [dropErrors.multipleErrors]: '',
    [dropErrors.oneFileAtATime]: '',
  }

  return (
    <div>
      {[
        dropErrors.fileAmount,
        dropErrors.fileType,
        dropErrors.maxFileSize,
        dropErrors.minFileSize,
      ].map((errorType) => {
        const files = filesByErrorType[errorType]
        return files.length ? (
          <div key={errorType}>
            <Typography weight="bold">{messages[errorType]}</Typography>
            <StyledErrorBannerDetails>
              {filesByErrorType[errorType].map((file) => (
                <li key={file.name}>{getFileDetail(file)}</li>
              ))}
            </StyledErrorBannerDetails>
          </div>
        ) : null
      })}
    </div>
  )
}

// make error banner accessible as a styled-components selector
export const DropzoneErrorBanner = styled(DropzoneErrorBannerBase)``

function DropzoneContainer({
  active,
  disabled,
  getRootProps,
  rootProps,
  children,
}: ContainerProps & Props) {
  return (
    <StyledDropzoneContainer
      {...getRootProps(rootProps)}
      active={active}
      disabled={disabled}
    >
      {children}
    </StyledDropzoneContainer>
  )
}

function DropzoneContent({
  disabled,
  isIconVisible,
  contentMessage,
}: ContentProps) {
  return (
    <StyledDropzoneMessage>
      {isIconVisible && (
        <StyledDropzoneIcon disabled={disabled}>
          {disabled ? <Ban /> : <Image />}
        </StyledDropzoneIcon>
      )}
      {contentMessage}
    </StyledDropzoneMessage>
  )
}

export function DropzoneDefaultMessage({
  open,
  disabled,
  multiple,
  errorMessage,
}: DropzoneContentProps & { multiple: boolean }) {
  const I18n = useI18nContext()

  return (
    <>
      <StyledUploadButton
        variant="secondary"
        onClick={open}
        disabled={disabled}
      >
        {I18n.t('core.dropzone.uploadFiles', {
          count: multiple ? Infinity : 1,
        })}
      </StyledUploadButton>
      <StyledDropzoneCaption error={!!errorMessage}>
        {errorMessage || I18n.t('core.dropzone.dragAndDrop')}
      </StyledDropzoneCaption>
    </>
  )
}

const dropErrorsByReason = {
  [fileRejectionReason.maxFiles]: dropErrors.fileAmount,
  [fileRejectionReason.maxFileSize]: dropErrors.maxFileSize,
  [fileRejectionReason.minFileSize]: dropErrors.minFileSize,
  [fileRejectionReason.fileType]: dropErrors.fileType,
} as Record<FileRejectionReason, DropErrorType>

const getSizeInMB = (maxSize: number): number => maxSize / 10e5

const Dropzone_ = React.forwardRef<DropzoneRef, DropzoneProps>(
  function Dropzone(
    {
      isIconVisible: forceIconVisibility,
      contentRenderer,
      disabled,
      tooltip,
      rootRef,
      inputRef,
      dragError,
      dropError,
      dispatchDropError,
      getRootProps,
      getInputProps,
      isDragActive,
      multiple,
      fileRejections,
      open,
      rootProps = {} as DropzoneRootProps,
      inputProps = {} as DropzoneInputProps,
      className,
      qa,
      tokens,
      onClear,
    },
    ref
  ) {
    const [isIconVisible, setIsIconVisible] = React.useState(false)
    const { isFocusWithin, containerProps } = useFocusWithin()

    const toggleIconVisibility = React.useCallback(
      (entries: ResizeObserverEntry[]) => {
        const { offsetHeight: height } = entries[0].target as HTMLElement
        setIsIconVisible(height >= extendedViewHeightBreakpoint)
      },
      [setIsIconVisible]
    )

    const setResizeObserverTarget = useResizeObserver(toggleIconVisibility)

    React.useEffect(() => {
      setResizeObserverTarget(rootRef.current)
    }, [rootRef, setResizeObserverTarget])

    React.useImperativeHandle(ref, () => ({
      rootRef: rootRef as React.RefObject<HTMLElement>,
      inputRef: inputRef as React.RefObject<HTMLInputElement>,
      open,
    }))

    const isDropzoneDisabled = disabled || Boolean(dragError)

    const contentMessage = React.useMemo(
      () =>
        contentRenderer ? (
          contentRenderer({
            open,
            disabled: isDropzoneDisabled,
            errorMessage: dragError,
          })
        ) : (
          <DropzoneDefaultMessage
            open={open}
            disabled={isDropzoneDisabled}
            errorMessage={dragError}
            multiple={multiple}
          />
        ),
      [contentRenderer, open, isDropzoneDisabled, dragError, multiple]
    )

    const validRootProps = React.useMemo(
      () => omit(['disabled'], rootProps),
      [rootProps]
    )

    const validInputProps = React.useMemo(
      () => omit(['accept', 'multiple', 'type'], inputProps),
      [inputProps]
    )

    return (
      <StyledDropzoneWrapper
        direction="column"
        alignItems="stretch"
        gap="xs"
        className={className}
      >
        <Tooltip
          trigger={Boolean(tooltip) ? 'hover' : 'none'}
          overlay={tooltip || ''}
        >
          <StyledDropzoneFocusDetector {...containerProps}>
            <DropzoneContainer
              rootProps={validRootProps}
              active={isDragActive || isFocusWithin}
              disabled={isDropzoneDisabled}
              getRootProps={getRootProps}
            >
              <input type="file" {...getInputProps(validInputProps)} />
              <DropzoneContent
                disabled={isDropzoneDisabled}
                isIconVisible={
                  typeof forceIconVisibility === 'undefined'
                    ? isIconVisible
                    : forceIconVisibility
                }
                contentMessage={contentMessage}
              />
            </DropzoneContainer>
          </StyledDropzoneFocusDetector>
        </Tooltip>
        <DropzoneErrorBanner
          qa={qa}
          error={dropError}
          fileRejections={fileRejections}
          onDismiss={() => dispatchDropError(dropErrors.reset)}
        />
        {tokens && tokens.length > 0 && (
          <Box as="ul" display="flex" flexWrap="wrap" gap="sm" paddingTop="lg">
            {tokens.map((token) => (
              <li key={token.name}>
                <Token>
                  <Token.Label>{getFileDetail(token)}</Token.Label>
                  {onClear && <Token.Remove onClick={() => onClear(token)} />}
                </Token>
              </li>
            ))}
          </Box>
        )}
      </StyledDropzoneWrapper>
    )
  }
)

DropzoneContainer.displayName = 'DropzoneContainer'

DropzoneContent.displayName = 'DropzoneContent'

Dropzone_.displayName = 'Dropzone'

/**

 Dropzones allow users to quickly upload files from their computers, as well
 as optionally launch the file select component.

 @since 10.19.0

 @see [Storybook](https://stories.core.procore.com/?path=/story/core-react_demos-dropzone--demo)

 @see [Design Guidelines](https://design.procore.com/dropzone)

*/
export const Dropzone = React.memo(Dropzone_, equals)
